import {useEffect, useState} from 'preact/hooks';

import {useBugsnag} from '~/foundation/Bugsnag/hooks';

interface StoreMetadata {
  id?: number;
  name?: string;
}

export function useStoreMetadata(storefrontOrigin: string) {
  const {notify} = useBugsnag();
  const [storeMetadata, setStoreMetadata] = useState<StoreMetadata | null>(
    null,
  );
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getMetadata = async () => {
      const metadata = await getStoreMeta(storefrontOrigin);
      if (metadata) {
        setStoreMetadata(metadata);
      } else {
        notify(new Error('Failed to fetch store metadata'));
      }

      setLoaded(true);
    };
    getMetadata();
  }, [notify, storefrontOrigin]);

  const storeName = storeMetadata?.name ?? 'the store';
  const storeId = storeMetadata?.id;

  return {loaded, storeId, storeMetadata, storeName};
}

async function getStoreMeta(
  storefrontOrigin: string = location.origin,
): Promise<StoreMetadata | null> {
  const response = fetch(`${storefrontOrigin}/meta.json`);
  try {
    const res = await response;
    const meta = await res.json();
    return meta;
  } catch {
    return null;
  }
}
